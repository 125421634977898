import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Grid, Paper } from '@mui/material';
import { AppBar, Toolbar ,Button} from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import Barra from './Barra';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function InformazioniEsteseSito() {
  const location = useLocation();
  const [isBarraOpen, setIsBarraOpen] = useState(false);
  const [cantieri, setCantieri] = useState([]); // Stato per memorizzare i cantieri
  const { site } = location.state || {};


  useEffect(() => {
    const fetchCantieri = async () => {
      try {
        const url="/ricercaCantiere";
        const response = await axios.get(url,site.ID);
        const data = await response.json();
        setCantieri(data); // Memorizza i cantieri nel componente
      } catch (error) {
        console.error('Errore durante il recupero dei cantieri:', error);
      }
    };
    fetchCantieri();
  }, [site]);




  if (!site) {
    return <Typography variant="h6">Nessun sito trovato</Typography>;
  }
  const toggleBarra = () => {
    setIsBarraOpen(!isBarraOpen); // Updated to toggle the sidebar state based on the passed argument
  };
 
  const gestioneBARRA=()=>{
    console.log("pulsante premuto");
   const a = true;
    setIsBarraOpen(a);
  }
 
  // Effettua una richiesta per ottenere i cantieri del sito
 



  return (
    <div  style={{ backgroundColor: '#39414C'}}>
    <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra}/>
    <AppBar position="static">
      <Toolbar sx={{backgroundColor: '#A0C646;'}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           LGS-Gestione Sito
         
        </Typography>
        <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={gestioneBARRA}>
        <AddCircleOutline />  
        </Button>
      </Toolbar>
    </AppBar>
    <Container>
      <Typography variant="h4" gutterBottom>
        Dettagli del sito: {site.nome}
      </Typography>
      <Grid container spacing={3}>
        {/* Informazioni Generali */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Informazioni Generali:</Typography>
              <Typography variant="body1">Cluster: {site.Cluster}</Typography>
              <Typography variant="body1">Nazione: {site.nazione}</Typography>
              <Typography variant="body1">Città: {site.citta}</Typography>
              <Typography variant="body1">Indirizzo: {site.indirizzo}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Informazioni sui Cantieri */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Cantieri</Typography>
              <Typography variant="body1">Cantieri Attivi: {site.ID}</Typography>
              <Typography variant="body1">Cantieri Completati: {site.cantieriCompletati}</Typography>
              <Typography variant="body1">Cantieri Totali: {site.cantieriTotali}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Project Manager e Ticket */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Project Manager e Ticket</Typography>
              <Typography variant="body1">Project Manager: Mario Rossi</Typography>
              <Typography variant="body1">Ticket Aperti: 30</Typography>
              <Typography variant="body1">Ticket Evasi: 121</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Aggiungere un'immagine o logo */}
        <Grid item xs={12}>
          <Paper elevation={3}>
            <img src={site.logo} alt={site.nome} style={{ width: '100%', height: 'auto' }} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  </div>
  );
}

export default InformazioniEsteseSito;