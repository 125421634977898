import React, { useState } from 'react';
import './App.css';
import Login from './modules/Login';
import Register from './modules/Register';
import Ispezione from './modules/Ispezione';
import CreaNuovoSito from './modules/creaNuovoSito';
import CreaCantiere from './modules/creaCantiere';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VerbaleVerifica from './modules/VerbaleVerifica';
import Home from './HOME/Home';
import ChiSiamo from './modules/ChiSiamo';
import TeamPage from './modules/TeamPage';
import ListaUtenti from './modules/ListaUtenti';
import ListaSiti from './modules/ListaSiti';
import NavigationBar from './modules/NavigationBar'
import DashBoard from './modules/DashBoard';
import Agenda from './modules/Agenda';
import InformazioniEsteseSito from './modules/InformazioniEsteseSito';
function App() {
  const [isAuthenticated, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const handleLoginClick = () => {
    setShowLogin(true);
    console.log("mi stai premendo");
  };

  const handleSignInClick = () => {
    setShowRegister(true);
    console.log("mi stai premendo");
  };

  const hideRegisterForm = () => {
    setShowRegister(false);
  };
  
  return (
    <Router>
      <div style={{ display: 'block' ,minHeight: '100vh'}}>
        <NavigationBar />
        <div style={{ flex: 1, padding: '1px' }}>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/Home" element={<Home/>}/>
            <Route path="/Chi Siamo" element={<ChiSiamo />}/>
            <Route path="/Il Team" element={<TeamPage/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/Register" element={<Register />} />
            <Route path = "/Dashboard" element={<DashBoard /> }/>
            <Route path="/creaSito" element={<CreaNuovoSito />} /> 
            <Route path="/creaCantiere" element={<CreaCantiere />}/>
            <Route path="/ispezione" element={<Ispezione />} isAuthenticated={isAuthenticated}/>
            <Route path="/verifica" element={<VerbaleVerifica />} isAuthenticated={isAuthenticated}/>
            <Route path="/Utenti"  element={<ListaUtenti />} isAuthenticated={isAuthenticated}/>
            <Route path="/Lista Siti" element={<ListaSiti/>} isAuthenticated={isAuthenticated}/>
            <Route path="/Agenda" element={<Agenda/>} isAuthenticated={isAuthenticated}/>
            <Route path="/creaNuovoSito" element={<CreaNuovoSito />} isAuthenticated={isAuthenticated} />
            <Route path="/sito/:id" element={<InformazioniEsteseSito/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
