import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importa il Link di React Router
import {
    CollectionsBookmark,
    Edit,
    Feedback,
    Help,
    PermMedia,
    UploadFile,
    Work,
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
 
function Barra({isBarraOpen, toggleBarra}) {
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: true,
    });


    const handleMouseEnter = (anchor, open) =>(event) =>
    {
    
        
    };

  const handleMouseLeave = (anchor, open) =>  (event)=>{

  };
 
  const handleLogOut = (event) => {
    localStorage.removeItem('token'); 
    navigate('/login');
  }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift"))
         {
            console.log("variabile open1:",open);
            return;
        }
        console.log("variabile open:",open);
        if (open) 
        {
            console.log("variabile open2:",open);
            handleMouseEnter(anchor, false);
            open=true;
        } 
        else {
            console.log("variabile open3:",open);
            handleMouseLeave(anchor, true);
            open=true;
        }
        
     
        console.log(state);
    };
 
    const itemsList = (anchor) => (
        <Box
            sx={{
                width: anchor === "top" || 
                    anchor === "bottom" ? "auto" : 250,
                backgroundColor: "#09212E",
                height: '100%'
            }}
            role="drawer"
         
        >
            <Typography
                sx={{ textAlign: "center", pt: 4, 
                    color: "green", fontSize: 20 }}
            >
               
            </Typography>
            <List>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/DashBoard">
                    <ListItemIcon sx={{ color: "white" }}>
                        {<Help />}
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/Lista Siti">
                    <ListItemIcon sx={{ color: "white" }}>
                        {<CollectionsBookmark />}
                    </ListItemIcon>
                    <ListItemText primary={"Siti"} />
                </ListItemButton>

                <ListItemButton sx={{ color: "white" }} component={Link} to="/Agenda">
                    <ListItemIcon sx={{ color: "white" }}>
                        {<UploadFile />}
                    </ListItemIcon>
                    <ListItemText primary={"Agenda"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to ="/Utenti" >
                    <ListItemIcon sx={{ color: "white" }}>
                        {<Edit />}
                    </ListItemIcon>
                    <ListItemText primary={"Utenti"}/>
                </ListItemButton>
            </List>
            <Divider />
            <Button
                sx={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: 10,
                    textAlign: "center",
                    padding: 1,
                    margin: 2,

                }}
                onClick={handleLogOut}
            >
                Log out
            </Button>
        </Box>
    );
 
    return (
        <div drawer anchor="right" >
            <center>
                
                 
                        <Drawer
                           
                            anchor={"right"}
                            open = {isBarraOpen}
                            onClose={()=>toggleBarra(false)}
                        >
                            {itemsList("right")}
                        </Drawer>
                   
            </center>
        </div>
    );
}
 
export default Barra;