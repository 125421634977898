import React, { useState, useReducer  } from 'react';
import { TextField, Select, MenuItem, Button, Typography, Grid,Divider} from '@mui/material';
import './Impresa.css';
import { FiberDvrRounded } from '@mui/icons-material';
import { te } from 'date-fns/locale';
import { set } from 'date-fns';

// Componente Impresa per gestire le informazioni di un'impresa singola
const Impresa = ({ onChange, onRemove }) => {
  // Variabili per il immagazinare le informazioni

  const [nomePersonaleSicurezza, setNomePersonaleSicurezza] = useState('');
  const [cognomePersonaleSicurezza, setCognomePersonaleSicurezza] = useState('');
  const [emailPersonaleSicurezza, setEmailPersonaleSicurezza] = useState('');
  const [telefonoPersonaleSicurezza, setTelefonoPersonaleSicurezza] = useState('');
  const [indirizzoPostaCertificata, setPostaCertificata] = useState('');
  const [cognomePreposto, setCognomePreposto] = useState('');
  const [nomePreposto, setNomePreposto] = useState('');
  const [emailPreposto, setEmailPreposto] = useState('');
  const [telefonoPreposto, setTelefonoPreposto] = useState('');
  const[indirizzoSedeLegale, setIndirizzoSedeLegale] = useState('');
  const [tipoImpresa, setTipoImpresa] = useState('');
  const [ragioneSociale, setRagioneSociale] = useState('');
  const [telefonoUfficio, setTelefonoUfficio] = useState('');
  const [emailImpresa, setEmailImpresa] = useState('');

  const initialState = {
    nomePersonaleSicurezza: '',
    cognomePersonaleSicurezza: '',
    emailPersonaleSicurezza: '',
    telefonoPersonaleSicurezza: '',
    indirizzoPostaCertificata: '',
    cognomePreposto: '',
    nomePrepostoImpresa: '',
    emailPreposto: '',
    telefonoPreposto: '',
    indirizzoSedeLegale: '',
    tipoImpresa: '',
    ragioneSociale: '',
    telefonoUfficio: '',
    emailImpresa: ''
  };
  

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return {
          ...state,
          [action.field]: action.value
        };
      default:
        return state;
    }
  }
  
  
  const [state, dispatch] = useReducer(reducer, initialState);

  
  const handleInputChange = (event) => {
    dispatch({ 
      type: 'SET_FIELD', 
      field: event.target.name,
      value: event.target.value 
    });
    onChange(event); // Propaga la modifica al componente padre
  };
  
  const onChangeTipoImpresa = (event) => {
    onChange(event); // Assuming `onChange` will handle the type change
    setTipoImpresa(event.target.value);
  };
return (
  <div className="mb-3">
    {/* Sezione Tipo Impresa e Ragione Sociale */}
    <Grid container spacing={3}>
      <Grid item xs={12} sm={2}>
        <Typography variant="h6">Tipo Impresa:</Typography>
        <Select
          name = "tipoImpresa"
          value = {state.tipoImpresa}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        >
          <MenuItem value="affidataria">Affidataria</MenuItem>
          <MenuItem value="esecutrice">Esecutrice</MenuItem>
          <MenuItem value="sub-appaltatrice">Sub-Appaltatrice</MenuItem>
        </Select>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Ragione Sociale:</Typography>
        <TextField
          id="ragioneSociale"
          type="text"
          name="ragioneSociale"
          value={state.ragioneSociale}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
    </Grid>

    {/* Sezione Indirizzi e Contatti */}
    <Typography variant="h6" style={{ marginTop: '24px' }}>
      Informazioni Sede Legale:
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="indirizzoPostaCertificata"
          label="Indirizzo Sede legale*"
          type="text"
          name = "indirizzoSedeLegale"
          value={state.indirizzoSedeLegale}
          fullWidth
          onChange={handleInputChange}
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id="email"
          label="Email*"
          type="text"
          name='emailImpresa'
          value={state.emailImpresa}
          fullWidth
          onChange={handleInputChange}
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id="telefonoUfficio"
          label="Telefono Ufficio"
          type="text"
          name ='telefonoUfficio'
          value ={state.telefonoUfficio}
          fullWidth
          onChange={handleInputChange}

          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id="postaCertificata"
          label="Indirizzo Posta Certificata"
          type="text"
          name='indirizzoPostaCertificata'
          value={state.indirizzoPostaCertificata}
          fullWidth
          onChange={handleInputChange}
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
    </Grid>

    {/* Sezione Cordinatore della Sicurezza */}
    <Typography variant="h6" style={{ marginTop: '24px' }}>
      Cordinatore della Sicurezza in Fase di Esecuzione:
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Nome"
          id="nomePersonaleSicurezza"
          name="nomePersonaleSicurezza"
          value={state.nomePersonaleSicurezza}
          onChange={(handleInputChange)}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Cognome"
          name='cognomePersonaleSicurezza'
          value={state.cognomePersonaleSicurezza}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          id="emailPersonaleSicurezza"
          name='emailPersonaleSicurezza'
          value={state.emailPersonaleSicurezza}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Recapito Telefonico"
          id="telefonoPersonaleSicurezza"
          name="telefonoPersonaleSicurezza"
          value={state.telefonoPersonaleSicurezza}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

    </Grid>

    {/* Sezione Preposto Impresa */}
    <Typography variant="h6" style={{ marginTop: '24px' }}>
      Preposto Impresa:
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Nome"
          name='nomePrepostoImpresa'
          value={state.nomePrepostoImpresa}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Cognome"
          name="cognomePrepostoImpresa"
          value={state.cognomePrepostoImpresa}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

       
    {/* Nuovi campi per Email e Recapito Telefonico */}
    <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          id="emailPreposto"
          name="emailPreposto"
          value={state.emailPreposto}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Recapito Telefonico"
          id="telefonoPreposto"
          name="telefonoPreposto"
          value={state.telefonoPreposto}
          onChange={handleInputChange}
          fullWidth
          InputProps={{
            style: {
              color: 'black',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
    </Grid>

    {/* Bottone Rimuovi Impresa */}
    <Button
      onClick={onRemove}
      variant="contained"
      color="error"
      style={{ marginTop: '24px' }}
    >
      Rimuovi Impresa
    </Button>
  </div>


  );
};

export default Impresa;