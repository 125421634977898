import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DettagliIspezione from './DettagliIspezione';
import { divIcon } from 'leaflet';

function Riga({ label }) {
  const [isChecked, setIsChecked] = useState(false);
  const [note, setNote] = useState('');
  const [osservazioni, setOsservazioni] = useState('');
  const [date, setDate] = useState('');
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };



  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleOsservazioniChange= (event)=>{
    setOsservazioni(event.target.value);
  };

  return (
  
   
    <tr>
      <td>{label}
      
      </td>
      <td>
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
      </td>

      
      <td>
      <input type="date" value={date} onChange={handleDateChange} />
      </td>

      <td>
        <input type="text" value={note} onChange={handleNoteChange} />
        </td>
      <td> 
        <input type="text" value={osservazioni} onChange={handleOsservazioniChange} />
      </td>
    </tr>

 
 
  );
}

export default Riga;