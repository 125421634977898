import React from 'react';
import Riga from './Riga';
import "./ispezione.css";
function VerificaAttivitaTitoloIV({ handleChange, dati }) {
    const data = [
        { label: 'Nomina RLP'},
        { label: 'Nomina RLE'},
        { label: 'Nomina CSP'},
        { label: 'Nomina CSE'},
        { label: 'Presenza PSC (art. 93 comma 2), D. Lgs. 81/08'},
        { label: 'Presenza fascicolo dellaopera'},
        { label: 'Presenza aggiornamenti PSC (come da art.92, comma 1, lett. b, D. Lgs. 81/08)'},
        { label: 'Presenza nel PSC dei contenuti minimi previsti (come da allegato XV, D. Lgs. 81/08)'},
        { label: 'Presenza nel PSC della stima di uomini-giorno'},
        { label: 'Presenza Notifica preliminare'},
        { label: 'Presenza aggiornamenti Notifica preliminare (come da art.99, comma 1, D. Lgs. 81/08)'},
        { label: 'Trasmissione alla amministrazione concedente, prima dell inizio dei lavori oggetto del titolo abilitativo, della copia della notifica preliminare del DURC delle imprese e della dichiarazione attestante della avvenuta verifica della ulteriore documentazione di cui al art.90 comma a e b del D.Lgs. 81/08 (art. 90 comma 9c del D.Lgs. 81/08)'},
        { label: 'Presenza POS Impresa Aff.'},
        { label: 'Presenza Aggiornamento POS Impresa Aff.'},
        { label: 'Elenco Lavoratori Impresa Aff.'},
        { label: 'Presenza idonea Formazione Lavoratori Impresa Aff.'},
        { label: 'Presenza UNILAV Lavoratori Impresa Aff.'},
        { label: 'Presenza POS Impresa Esec.'},
        { label: 'Presenza Aggiornamento POS Impresa Esec.'},
        { label: 'Elenco Lavoratori Impresa Esec.'},
        { label: 'Presenza idonea Formazione Lavoratori Impresa Esec..'},
        { label: 'Presenza UNILAV Lavoratori Impresa Esec..'},
        { label: 'Presenza POS Impresa Esec.'},
        { label: 'Presenza Aggiornamento POS Impresa Esec.'},
        { label: 'Elenco Lavoratori Impresa Esec.'},
        { label: 'Presenza idonea Formazione Lavoratori Impresa Esec..'},
        { label: 'Presenza UNILAV Lavoratori Impresa Esec..'},
        { label: 'Presenza di Autorizzazione Subapp.'},
        { label: 'Ingresso in cantiere imp. Subappaltatrice'},
        { label: 'Evidenza della verifica sull attuazione degli obblighi a carico del Datore di lavore dell imprersa aff. previsti dell art.97 comma 3bis (art. 100 comma 6bis)'},
        { label: 'Presenza documentazione per la Verifica di Idoneità Tecnico Professionale (come da Allegato XVII, D. Lgs. 81/08)'},
        { label: 'Presenza documentazione per la Verifica di Idoneità Tecnico Professionale subappaltatori(come da Allegato XVII, comma 3, D. Lgs. 81/08)'},
        { label: 'Avvenuta indicazione da parte dell impresa aff. del nominativo del soggetto incaricato per l assolvimento dei compiti di cui all art. 97'},
        { label: 'Verifica della formazione del soggetto incaricato degli compiti di cui all art. 97 (art. 100 comma 6bis del D.Lgs. 81/08)'},
        { label: 'Nomina Preposto Impresa Aff.'},
        { label: 'Nomina Preposto Impresa Esec.'},
        { label: 'Verbali di sopralluogo emessi dal CSE (verificare che i verbali siano redatti anche in assenza di irregolarità rilevate)'},
        { label: 'CON QUALE FREQUENZA SONO EFFETTUATI I SOPRALLUOGHI DEL CSE?'}, 
        { label: 'LE DATE SOPRACITATE SONO TEMPORALMENTE CONGRUENTI?'}
      ];

  return (
  
    <table>
      <thead>
        <tr>
          <th>Documento</th>
          <th>Presente</th>
          <th>Data</th>
          <th>Note</th>
          <th>Osservazioni</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <Riga key={item.label} label={item.label} />
        ))}
      </tbody>
    </table>

  );
}

export default VerificaAttivitaTitoloIV;