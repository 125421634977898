import React, { useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import './FiltroBar.css';

const FiltroBar = ({ onFilterChange, resetFilters }) => {
  const [filters, setFilters] = useState({
    nome: '',
    nazione: '',
    cluster: '',
    progressoMin: '',
    progressoMax: '',
    ProgectManager: ''

  });

  // Funzione per gestire il cambiamento dei filtriS
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <div className='filtro-bar' style={{ display: 'flex', gap: '10px', padding: '10px' }}>
      <TextField
        label="Nome sito"
        name="nome"
        variant="outlined"
        size="small"
        value={filters.nome}
        onChange={handleChange}
      />
      
      <FormControl variant="outlined" size="small">
        <InputLabel>Nazione</InputLabel>
        <Select
          name="nazione"
          value={filters.nazione}
          onChange={handleChange}
          label="Nazione"
        >
          <MenuItem value="">Tutte</MenuItem>
          <MenuItem value="Italia">Italia</MenuItem>
          <MenuItem value="USA">USA</MenuItem>
          <MenuItem value="Francia">Francia</MenuItem>
        
        </Select>
      </FormControl>
      <FormControl variant="outlined" size="small">
        <InputLabel>Cluster</InputLabel>
        <Select
          name="cluster"
          value={filters.cluster}
          onChange={handleChange}
          label="Cluster"
        >
          <MenuItem value="">Tutte</MenuItem>
          <MenuItem value="NORD">NORD</MenuItem>
          <MenuItem value="NORD EST">NORD EST</MenuItem>
          <MenuItem value="NORD OVEST"> NORD OVEST</MenuItem>
          <MenuItem value="CENTRO">CENTRO</MenuItem>
          <MenuItem value="TIRRENICA">TIRRENICA</MenuItem>
          <MenuItem value="SUD">SUD</MenuItem>
          <MenuItem value="SUD EST">SUD EST</MenuItem>
          <MenuItem value="SUD OVEST">SUD OVEST</MenuItem>
          
        </Select>
      </FormControl>

      <TextField
        label="Progresso Min %"
        name="progressoMin"
        variant="outlined"
        size="small"
        type="number"
        value={filters.progressoMin}
        onChange={handleChange}
      />

      <TextField
        label="Progresso Max %"
        name="progressoMax"
        variant="outlined"
        size="small"
        type="number"
        value={filters.progressoMax}
        onChange={handleChange}
      />
       <TextField
        label="Progect Manager"
        name="progectManager"
        variant="outlined"
        size="small"
        type="text"
        value={filters.ProgectManager}
        onChange={handleChange}
      />

      <Button variant="contained" color="primary" onClick={() => resetFilters()}>Reset</Button>
    </div>
  );
};

export default FiltroBar;