import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Box, Container, IconButton, InputAdornment, Card, CardContent } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoginForm, setIsLoginForm] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = isLoginForm ? '/login' : '/register';
    const data = { email, password };

    if (!isLoginForm) {
      data.firstName = data.lastName = '';
    }

    try {
      const response = await axios.post(url, data);

      if (isLoginForm) {
        setMessage('Login successful');
        navigate('/DashBoard');
      } else {
        setMessage('Registration successful. Please login to continue.');
        setEmail('');
        setPassword('');
        
      }
    } catch (error) {
      setMessage(isLoginForm ? 'Login failed' : 'Registration failed');
    }
  };

  const toggleForm = () => {
    navigate('/Register');
    setMessage('');
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div
      className="login-container"
      style={{
        height: '100vh',
        backgroundImage: 'url(/login.jpg)', // Percorso relativo all'immagine
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
   
   

    <Container maxWidth="sm"  sx={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
    }}>
      <Box 
        sx={{ 
          marginTop: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}
      >
        <Card variant="outlined" sx={{ width: '100%', borderRadius: 2 }}>
          <CardContent>
            <Typography component="h1" variant="h5" align="center" sx={{ mb: 3 }}>
              {isLoginForm ? 'Login' : 'Registration'}
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    sx={{ bgcolor: 'background.paper' }} // Colore di sfondo del TextField
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    sx={{ bgcolor: 'background.paper' }} // Colore di sfondo del TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />} 
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: '#007bff', '&:hover': { bgcolor: '#0056b3' } }} // Colore personalizzato
              >
                {isLoginForm ? 'Log in' : 'Register'}
              </Button>
              
              <Button
                fullWidth
                variant="text"
                sx={{ mt: 1 }}
                onClick={toggleForm}
                color="primary"
              >
                {isLoginForm ? 'Sign up' : 'Log in'}
              </Button>

              {message && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {message}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
    </div>
  );
};

export default Login;
