import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DettagliIspezione from './DettagliIspezione';
import { divIcon } from 'leaflet';

function DataRow({ label }) {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [note, setNote] = useState('');

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };


  return (
  
   
    <tr>
      <td>{label}
      
      </td>
      <td>
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
      </td>
      <td>
        <input type="file" onChange={handleFileChange} />
      </td>
      <td>
        <input type="text" value={note} onChange={handleNoteChange} />
      </td>
      
    </tr>

 
 
  );
}

export default DataRow;