
import React, { useEffect, useState,useReducer } from 'react';
import { TextField, Button, Typography, Grid, Box, IconButton, InputLabel, MenuItem, Select, Divider,Toolbar,AppBar } from '@mui/material';
import { useNotifications } from '../NotificationContext';
import { useLocation } from 'react-router-dom';
import Impresa from './Impresa';
import 'react-datepicker/dist/react-datepicker.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { it } from 'date-fns/locale';
import { format } from 'date-fns'; // Importa la funzione format
import Barra from './Barra';
import { AddCircleOutline } from '@mui/icons-material';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function CreaCantiere() {

  const initialState = {
    nomePersonaleSicurezza: '',
    cognomePersonaleSicurezza: '',
    emailPersonaleSicurezza: '',
    telefonoPersonaleSicurezza: '',
    indirizzoPostaCertificata: '',
    cognomePreposto: '',
    nomePrepostoImpresa: '',
    emailPreposto: '',
    telefonoPreposto: '',
    indirizzoSedeLegale: '',
    tipoImpresa: '',
    ragioneSociale: '',
    telefonoUfficio: '',
    emailImpresa: ''
  };


  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return {
          ...state,
          [action.field]: action.value
        };
      default:
        return state;
    }
  };


  const [state, dispatch] = useReducer(reducer, initialState);

  const [nome, setNome] = useState('');
  
  const [indirizzo, setIndirizzo] = useState('');
  
  const [cluster, setCluster] = useState('');
  
  const [descrizione, setDescrizione] = useState('');
  
  const [documenti, setDocumenti] = useState([]);
  
  const [startDate, setStartDate] = useState(null);
  
  const [endDate, setEndDate] = useState(null);
  
  const [importolavori, setImportoLavori] = useState('');
  
  const [ODA, setODA] = useState('');
  
  const [dataInizioLavori, setDataInizioLavori] = useState(null);
  
  const [dataFineLavori, setDataFineLavori] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [nomeRL, setNomeRL] = useState('');
  
  const[cognomeRL, setCognomeRL] = useState('');
  
  const[emailRL, setEmailRL] = useState('');
  
  const [telefonoRL, setTelefonoRL] = useState('');

  const { getNotifications } = useNotifications();

  const [isBarraOpen, setIsBarraOpen] = useState(false);

  const location = useLocation();
  
  const sito = location.state?.sito || {}; // Ottieni i dati del sito

  const [imprese, setImprese] = useState([initialState]);

  const handleAddImpresa = () => {
    setImprese([...imprese, { tipo: '', nome: '' }]);
  };

  const handleChangeImpresa = (index, e) => {
    const { name, value } = e.target;
    setImprese((prevImprese) =>
      prevImprese.map((impresa, i) =>
        i === index ? { ...impresa, [name]: value } : impresa
      )
    );
  };

  const handleRemoveImpresa = (index) => {
    const updatedImprese = [...imprese];
    updatedImprese.splice(index, 1);
    setImprese(updatedImprese);
  };

 
  const toggleBarra = () => {
    setIsBarraOpen(!isBarraOpen); // Updated to toggle the sidebar state based on the passed argument
  };
 
  const handleEmailRLChange = (event) => {
    setEmailRL(event.target.value);
  }
  const handleNomeRLChange = (event) => {
    if (event.target) {
      setNomeRL(event.target.value);
    }
  }
  const handleCognomeRLChange = (event) => {
    if (event.target) {
      setCognomeRL(event.target.value);
    }
  }
  const handleTelefonoRLChange = (event) => {
    if (event.target) {
      setTelefonoRL(event.target.value);
    }
  }


  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleIndirizzoChange = (event) => {
    setIndirizzo(event.target.value);
  };

  const handleDescrizioneChange = (event) => {
    setDescrizione(event.target.value);
  };

  const handleFileChange = (event) => {
    setDocumenti([...documenti, event.target.files[0]]);
  };

  const creaCantiere = async() => {
    // logica per creare il cantiere
    if (imprese === null || imprese.length === 0) {
      console.error("Impossibile creare il cantiere: lista imprese vuota");
      return;
    }

    try {

      const url = '/creaCantiere';

      const  data = {descrizione, nome, cluster, indirizzo, documenti, importolavori, ODA, dataInizioLavori, dataFineLavori, imprese };
      
      const response = await axios.post(url, data);

      if (response.status === 200) {
         console.log("Cantiere creato con successo!");
      

          imprese.forEach((impresa, index) => {

          if (impresa === null) {
            console.error(`Impossibile creare il cantiere: impresa ${index} non valida`);
            return;
          }
          console.log("informazioni impresa:", index)
          console.log("tipologia azienda:", impresa.tipoImpresa);
          console.log("ragione sociale:", impresa.ragioneSociale);
          console.log("indirizzosede legale:", impresa.indirizzoSedeLegale);
          console.log("telefono ufficio", impresa.telefonoUfficio);
          console.log("postacertificata", impresa.indirizzoPostaCertificata);
          console.log("Nome preposto:", impresa.nomePrepostoImpresa);
          console.log("Cognome preposto:", impresa.cognomePreposto);
          console.log("Email preposto:", impresa.emailPreposto);
          console.log("Telefono preposto:", impresa.telefonoPreposto);
          console.log("Nome personale sicurezza:", impresa.nomePersonaleSicurezza);
          console.log("Cognome personale sicurezza:", impresa.cognomePersonaleSicurezza);
          console.log("Email personale sicurezza:", impresa.emailPersonaleSicurezza);
          console.log("Telefono personale sicurezza:", impresa.telefonoPersonaleSicurezza);

        });
      }
     else
      {
      console.error("Errore nella creazione del cantiere:", response.statusText);
      }
    } catch (error) {
      console.error(`Impossibile creare il cantiere: ${error.message}`);
    }
  };

  const getOda = (event) => {
    setODA(event.target.value);
  };

  const getImportoLavori = (event) => {
    setImportoLavori(event.target.value);
  };

  const getStartDate = (date) => {
    setStartDate(date);
  };  

  const handleDataInizioLavori = (newdata) => {
     if(newdata)
     {
      console.log(newdata);
      const formattedDate = format(newdata, 'dd/MM/yyyy');
      setDataInizioLavori(formattedDate);
     }
    
  };
  const handleDataFineLavori = (newdata) => {
    
    if(newdata)
    {
      const formattedDate = format(newdata, 'dd/MM/yyyy');
      //setDataInizioLavori(formattedDate);
      setDataFineLavori(formattedDate);
      
    }
  };

  const handleCalculateDays = () => {
    if (startDate && endDate) {
      const days = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
      alert(`I giorni tra le due date sono: ${days}`);
    } else {
      alert('Inserisci entrambe le date per calcolare i giorni.');
    }
  };

  const gestioneBARRA=()=>{
    console.log("pulsante premuto");
   const a = true;
    setIsBarraOpen(a);
  }

  useEffect(() => {
    const notifications = getNotifications();
    notifications.forEach((notification) => {
      setIndirizzo(notification.message.selectedSito);
      setCluster(notification.message.selectedCluster);
    });
  
    setCluster(sito.Cluster);
    setIndirizzo(sito.indirizzo);
    setNome(sito.citta);
   setIsBarraOpen(false);

  }, []);

  return (
    <div  style={{ backgroundColor: '#39414C'}}>
       <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra}/>
      <AppBar position="static">
        <Toolbar sx={{backgroundColor: '#A0C646;'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
             LGS - Crea un Nuovo Cantiere
           
          </Typography>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={gestioneBARRA}>
          <AddCircleOutline />  
          </Button>
        </Toolbar>
      </AppBar>



    <Box p={3}>
      <Box 
      mb={3} 
      p={2}
      bgcolor="#e0f7fa"
      borderRadius={4}
      border={1} // Aggiunge il bordo
      borderColor="black" // Colore del bordo
      >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cluster"
            variant="outlined"
            fullWidth
            value={cluster}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>
      
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome del Cantiere"
            variant="outlined"
            fullWidth
            value={nome}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Indirizzo del Cantiere"
            variant="outlined"
            fullWidth
            value={indirizzo}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrizione"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={descrizione}
            onChange={handleDescrizioneChange}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>


       {/*
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Carica Documenti
            <input type="file" hidden multiple onChange={handleFileChange} />
          </Button>
        </Grid>
  
        <Grid item xs={12}>
          <Typography variant="h6">Documenti caricati:</Typography>
          <ul>
            {documenti.map((doc, index) => (
              <li key={index}>
                {doc.name}
                <a href={URL.createObjectURL(doc)} download>Scarica</a>
              </li>
            ))}
          </ul>
        </Grid>
         */}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Ordine di Acquisto"
            variant="outlined"
            fullWidth
            value={ODA}
            onChange={getOda}
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
               
              }
            }}
            sx={{ width: '100%' }} // Assicurati che la larghezza sia 100%
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Importo dei Lavori"
            variant="outlined"
            fullWidth
            type="number"
            
            value={importolavori}
            onChange={getImportoLavori}
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
                
              }
              
            }}
            sx={{ width: '100%' }} // Assicurati che la larghezza sia 100%
          />
        </Grid>
        
        
        <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                <DatePicker
                  label="Data di Inizio Lavori"
                  variant="outlined"
                  value={dataInizioLavori}
                  onChange={handleDataInizioLavori}
                  renderInput={(params) => (
                    <TextField
                      {...params}  // Aggiungi questo per gestire correttamente i parametri del DatePicker
                     
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                <DatePicker
                  label="Data di Fine Lavori"
                  variant="outlined"
                  value={dataFineLavori}
                  onChange={handleDataFineLavori}
                
                  renderInput={(params) => (
                    <TextField
                      {...params}  // Aggiungi questo per gestire correttamente i parametri del DatePicker
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
         </Grid>

        </Grid>
        </Box>
       


       {/*Sezione Responsabile dei lavori */}
        
       <Grid item xs={12}>
  <Typography variant="h6">Responsabile dei Lavori:</Typography>
</Grid>
<Box
 mb={3}
  p={2}
   label="Responsabile dei lavori"
   variant="outlined"
   bgcolor="#ffe0b2" 
   borderRadius={4}
   border={1} // Aggiunge il bordo
   borderColor="black" // Colore del bordo
   >
  <Grid container spacing={3}>
    {/* Prima riga: Nome e Cognome */}
    <Grid item xs={12} sm={6}>
      <TextField
        label="Nome"
        variant="outlined"
        value={nomeRL}
        onChange={handleNomeRLChange}
        fullWidth
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label="Cognome"
        variant="outlined"
        fullWidth
        value={cognomeRL}
        onChange={handleCognomeRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>

    {/* Seconda riga: Email e Telefono */}
    <Grid item xs={12} sm={6}>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={emailRL}
        onChange={handleEmailRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label="Telefono"
        variant="outlined"
        fullWidth
        value={telefonoRL}
        onChange={handleTelefonoRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>
  </Grid>
</Box>
     
      <Box mb={3} 
      p={2} 
      bgcolor="#f1e0b2" borderRadius={4}
      border={1} // Aggiunge il bordo
      borderColor="black" // Colore del bordo
      >
       <Typography variant="h6" gutterBottom>Imprese</Typography>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddImpresa}>
            Aggiungi Impresa
          </Button>
        </Grid>

        {imprese.map((impresa, index) => (
          <Grid item xs={12} key={index}>
            <Impresa
              
              state={state} 
              dispatch={dispatch}
             name="nome"
             value={impresa.nome}

              onChange={(e) => handleChangeImpresa(index, e)}
              onRemove={() => handleRemoveImpresa(index)}
            />
            <IconButton onClick={() => handleRemoveImpresa(index)}>
              <DeleteIcon />
            </IconButton>
            <Divider sx={{ 
              borderColor: 'black',
              borderWidth:3,
              borderStyle:'solid',
             }} />
          </Grid>
        ))}
        
        </Grid>
        </Box>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={creaCantiere}>
            Salva Cantiere
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={creaCantiere}>
            Genera Documento
          </Button>
        </Grid>

      </Grid>
    </Box>
    </div>
  );
 
}
export default CreaCantiere;