import React from 'react';

function ImpresaAffidataria({ handleChange, dati }) {
  return (
    <div>
      <h2>Impresa Affidataria</h2>

      {/* Dati identificativi dell'impresa */}
      <label>Ragione sociale:</label>
      <input type="text" name="ragioneSociale" value={dati.ragioneSociale} onChange={handleChange} />
      <label>Partita IVA:</label>
      <input type="text" name="partitaIVA" value={dati.partitaIVA} onChange={handleChange} />
      <label>Indirizzo sede legale:</label>
      <input type="text" name="indirizzoSedeLegale" value={dati.indirizzoSedeLegale} onChange={handleChange} />
      <label>Indirizzo cantiere/sede dell'attività ispezionata:</label>
      <input type="text" name="indirizzoCantiere" value={dati.indirizzoCantiere} onChange={handleChange} />

      {/* Informazioni sul contratto */}
      <label>Numero di contratto:</label>
      <input type="text" name="numeroContratto" value={dati.numeroContratto} onChange={handleChange} />
      <label>Data di inizio:</label>
      <input type="date" name="dataInizioContratto" value={dati.dataInizioContratto} onChange={handleChange} />
      <label>Data di fine:</label>
      <input type="date" name="dataFineContratto" value={dati.dataFineContratto} onChange={handleChange} />
      <label>Oggetto del contratto:</label>
      <textarea name="oggettoContratto" value={dati.oggettoContratto} onChange={handleChange} />

      {/* Referente tecnico dell'impresa */}
      <label>Nome e cognome del referente tecnico:</label>
      <input type="text" name="referenteTecnicoNome" value={dati.referenteTecnicoNome} onChange={handleChange} />
      <label>Ruolo del referente tecnico:</label>
      <input type="text" name="referenteTecnicoRuolo" value={dati.referenteTecnicoRuolo} onChange={handleChange} />
      <label>Telefono del referente tecnico:</label>
      <input type="tel" name="referenteTecnicoTelefono" value={dati.referenteTecnicoTelefono} onChange={handleChange} />
      <label>Email del referente tecnico:</label>
      <input type="email" name="referenteTecnicoEmail" value={dati.referenteTecnicoEmail} onChange={handleChange} />

      {/* Documentazione presentata */}
      <label>Documentazione presentata:</label>
      <textarea name="documentazionePresentata" value={dati.documentazionePresentata} onChange={handleChange} />
    </div>
  );
}

export default ImpresaAffidataria;