import React, { useState, useEffect } from 'react';

import axios from 'axios'; // Assuming axios is installed
import { MenuItem,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material//Delete';
import  Update  from '@mui/icons-material/Update';
import { AddCircleOutline } from '@mui/icons-material';
import {Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/icons-material/RadioButtonChecked';
import { Row, Col } from 'react-bootstrap';
import "./ListaUtenti.css"
import { AppBar, Toolbar, Typography, Grid} from '@mui/material';
import Barra from './Barra';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


const ricerca= async()=>
    {
        try
        {
            const url1 = "/Utenti"
         //   const url = new URL(url1,  "https://demosgs.it");
           
            //prende dal database i siti con gli indirizzi indirizzi 
            const response = await axios.get(url1);
            return response.data;

        }
        catch(error)
        {
            console.log(error)
            return null;
        }


    };


const ListaUtenti = () => {
    const [utenti, setUtenti] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [IDuser,setIDuser]=useState();
    const [ruoloUtente, setRuoloUtente] = useState('');
    const [utenteSelezionato,setUtenteSelezionato]= useState();
    const [searchInput, setSearchInput] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // Ordine di default
    const [searchNome, setSearchNome] = useState('');
    const [searchCognome, setSearchCognome] = useState('');
    const [isBarraOpen, setIsBarraOpen] = useState(false);

    const toggleBarra = () => {
      setIsBarraOpen(!isBarraOpen); // Updated to toggle the sidebar state based on the passed argument
    };
   
    const gestioneBARRA=()=>{
      console.log("pulsante premuto");
     const a = true;
      setIsBarraOpen(a);
    }
  




const handleNomeSearchChange = (e) => {
  setSearchNome(e.target.value);
};

const handleCognomeSearchChange = (e) => {
  setSearchCognome(e.target.value);
};  
    // ricerca utenti  
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const filteredData = utenti.filter(utente =>
        utente.nome.toLowerCase().includes(searchNome.toLowerCase()) &&
        utente.cognome.toLowerCase().includes(searchCognome.toLowerCase())
    );


const handleCloseDialog = () => {
    setOpenDialog(false);
}
    

    useEffect(() => 
    {
        const fetchUtenti = async () => {
            const result = await ricerca();
            if (result) {
                setUtenti(result);
            }
        };
        setIsBarraOpen(false);
        fetchUtenti();
    }, 
    []);

    const fetchElimina = async(userId)=>
    {   
     try
     {     const url = new URL('/EliminaUtenti');
           console.log("elimina=",userId)
           const risposta = await axios.post(url,[userId]);
           console.log(risposta)
           if(risposta.status==201)
           {
                //inserire un messaggio

           }
           else
           {
               //inserire un messaggio
           }
     }
     catch(error)
     {
            
     }
    };




    /*Gestisce l'eliminazione dell'utente*/
    const handleDelete = (userId) => {
        
       fetchElimina(userId);
       handleCloseDialog();      
    };

    /*gestisce l'aggiornamento dello stato dell'utente */

    const handleUpdate =(userId) =>
    {

    };

    const handleEdit = (userId) => {
        // Logica per modificare le informazioni dell'utente con l'ID 'userId'
        console.log("sto modificado l'utente:", userId);
    };

    const handleSort = () => {
        const sortedUsers = utenti.sort((a, b) => 
        {
            if (sortOrder === 'asc') {
                return a.nome.localeCompare(b.nome);
            } else {
                return b.nome.localeCompare(a.nome);
            }
        });

        setUtenti([...sortedUsers]);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }


    const handleStateChange =(event)=>
    {
        
    };

 



    const handleOpenDialog = (utente) => 
    {
        setUtenteSelezionato(utente);
        setOpenDialog(true);
    };




    return (
      <div>
        <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra}/>
        <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
            Gestione Utenti
          </Typography>
        
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={gestioneBARRA}>
          <AddCircleOutline />  Nuovo Cantiere
          </Button>
        </Toolbar>
      </AppBar>



        <TableContainer component={Paper}>
            
            

            <Table>
                <TableHead>
                    <TableRow>
                   
                    <TableCell>Nome
                     <IconButton onClick={handleSort}>
                      {sortOrder === 'asc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                     </IconButton>
                     <input type="text"
                      placeholder=""
                      onChange={handleNomeSearchChange} 
                     className="ricerca-nome"/>
                    </TableCell>
                      
                        
                            
                        <TableCell>Cognome
                         <IconButton onClick={handleSort}>
                           {sortOrder === 'asc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                        </IconButton>
                        <input 
                        type="text" 
                        placeholder="" 
                        className="ricerca-cognome"
                        onChange={handleCognomeSearchChange} 
                        />
                        
                        </TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell>Stato</TableCell>
                        <TableCell>Ruolo</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((utente,index) => (
                        <TableRow key={index}>
                            <TableCell>{utente.nome}</TableCell>
                            <TableCell>{utente.cognome}</TableCell>
                            <TableCell>{utente.email}</TableCell>
                            <TableCell>{utente.password}</TableCell>
                            
                            <TableCell>
                            <Select value={utente.stato} onChange={handleStateChange}>
                            <MenuItem value="SOSPESO">SOSPESO</MenuItem>
                            <MenuItem value="ATTIVO">ATTIVO</MenuItem>
                            <MenuItem value="INATTIVO">INATTIVO</MenuItem>
                            </Select>
                            </TableCell>
                            <TableCell>

                            <Select value={utente.ruolo} onChange={handleStateChange}>
                            <MenuItem value="ADMIN">ADMIN</MenuItem>
                            <MenuItem value="USERLGS">USERGUEST</MenuItem>
                            <MenuItem value="USERGUEST">USERLGS</MenuItem>
                            </Select>
                            </TableCell>

                                          
                            <TableCell>
                                <Button onClick={() => handleEdit(utente.ID)}   startIcon ={<EditIcon/>} ></Button>
                                <Button onClick={() => handleUpdate(utente.ID)} startIcon ={<Update/>}></Button>
                                <Button onClick={() => handleOpenDialog(utente)} startIcon ={<DeleteIcon/>}></Button> 
                                <Dialog open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle>Conferma eliminazione</DialogTitle>
                                <DialogContent>
                                <p>Sei sicuro di voler eliminare questo utente?</p>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">Annulla</Button>
                                <Button onClick={()=>handleDelete(utenteSelezionato.ID)} color="secondary">Elimina</Button>
                                </DialogActions>
                                </Dialog> 
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </div>
    );
};

export default ListaUtenti;