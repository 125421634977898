import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Agenda = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    // Carica gli appuntamenti dal server
    const loadAppointments = async () => {
      try {
        const response = await fetch('/api/appointments');
        const data = await response.json();
        setAppointments(data);
      } catch (error) {
        console.error(error);
      }
    };
    loadAppointments();
  }, []);

  const handleAddAppointment = async (date, title) => {
    // Aggiunge un nuovo appuntamento al server
    const newAppointment = {
      date: date.format('YYYY-MM-DD'),
      title,
    };
    try {
      const response = await fetch('/api/appointments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newAppointment),
      });
      if (response.status === 201) {
        // Aggiorna gli appuntamenti nel componente
        const updatedAppointments = [...appointments, newAppointment];
        setAppointments(updatedAppointments);
      } else {
        console.error('Errore durante l\'aggiunta dell\'appuntamento');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAppointment = async (id) => {
    // Cancella un appuntamento dal server
    try {
      const response = await fetch(`/api/appointments/${id}`, {
        method: 'DELETE',
      });
      if (response.status === 204) {
        // Aggiorna gli appuntamenti nel componente
        const updatedAppointments = appointments.filter(appointment => appointment.id !== id);
        setAppointments(updatedAppointments);
      } else {
        console.error('Errore durante la cancella dell\'appuntamento');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>Agenda</h1>
      <div>
        <h2>Aggiungi un appuntamento</h2>
        <input type="date" onChange={(event) => handleAddAppointment(moment(event.target.value), '')} />
        <input type="text" placeholder="Titolo" onChange={(event) => handleAddAppointment(moment(), event.target.value)} />
        <button onClick={() => handleAddAppointment(moment(), '')}>Aggiungi</button>
      </div>
      <div>
        <h2>Appuntamenti</h2>
        {appointments.map(appointment => (
          <div key={appointment.id}>
            <span>{moment(appointment.date).format('DD/MM/YYYY')}</span>
            <span>{appointment.title}</span>
            <button onClick={() => handleDeleteAppointment(appointment.id)}>Cancella</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Agenda;