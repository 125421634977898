import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Select, MenuItem, Button,AppBar,Toolbar} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AddCircleOutline } from '@mui/icons-material';
import Barra from './Barra';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const regionCoordinates = {
  Africa: { lat: -1.2921, lng: 36.8219 },
  Asia: { lat: 34.0479, lng: 100.6197 },
  NorthEurope: { lat: 60.4720, lng: 8.4689 },
  SouthEurope: { lat: 41.9028, lng: 12.4964 },
  EasternEurope: { lat: 55.3781, lng: 3.4360 },
  WesternEurope: { lat: 48.8566, lng: 2.3522 },
  NorthAmerica: { lat: 54.5260, lng: -105.2551 },
  SouthAmerica: { lat: -14.2350, lng: -51.9253 },
  Oceania: { lat: -25.2744, lng: 133.7751 },
  Antarctica: { lat: -82.8628, lng: 135.0000 },
  MiddleEast: { lat: 25.276987, lng: 55.296249 },
};

const regionStates = {
  Africa: ['Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Camerun', 'Capo Verde', 'Repubblica Centrafricana', 'Chad', 'Comore', 'Congo', 'Costa d\'Avorio', 'Egitto', 'Etiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea', 'Kenya', 'Lesotho', 'Liberia', 'Libia', 'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Marocco', 'Mozambico', 'Namibia', 'Niger', 'Nigeria', 'Ruanda', 'Senegal', 'Seychelles', 'Sierra Leone', 'Somalia', 'Sudafrica', 'Sudan', 'Tanzania', 'Togo', 'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'],
  Asia: ['Cina', 'Giappone', 'India', 'Indonesia', 'Pakistan', 'Bangladesh', 'Vietnam', 'Thailandia', 'Corea del Sud', 'Malesia', 'Nepal', 'Afghanistan', 'Iran', 'Sri Lanka', 'Singapore', 'Cambogia', 'Myanmar', 'Corea del Nord', 'Mongolia', 'Filippine'],
  NorthEurope: ['Norvegia', 'Svezia', 'Danimarca', 'Finlandia', 'Islanda', 'Estonia', 'Lettonia', 'Lituania'],
  SouthEurope: ['Italia', 'Spagna', 'Grecia', 'Portogallo', 'Malta', 'Albania', 'Cipro'],
  EasternEurope: ['Russia', 'Polonia', 'Ucraina', 'Ungheria', 'Romania', 'Bulgaria', 'Slovacchia', 'Serbia', 'Croazia', 'Slovenia', 'Bosnia ed Erzegovina'],
  WesternEurope: ['Francia', 'Germania', 'Belgio', 'Paesi Bassi', 'Lussemburgo', 'Svizzera', 'Austria', 'Monaco', 'Liechtenstein'],
  NorthAmerica: ['Stati Uniti', 'Canada', 'Messico', 'Guatemala', 'Honduras', 'Costa Rica', 'Panama', 'El Salvador', 'Cuba', 'Giamaica', 'Repubblica Dominicana', 'Haiti'],
  SouthAmerica: ['Brasile', 'Argentina', 'Cile', 'Perù', 'Colombia', 'Uruguay', 'Paraguay', 'Ecuador', 'Bolivia', 'Venezuela', 'Guyana', 'Suriname'],
  Oceania: ['Australia', 'Nuova Zelanda', 'Figi', 'Samoa', 'Papua Nuova Guinea', 'Tonga', 'Vanuatu'],
  Antarctica: ['Nessun stato'],
  MiddleEast: ['Arabia Saudita', 'Emirati Arabi Uniti', 'Qatar', 'Israele', 'Libano', 'Giordania', 'Iran', 'Iraq', 'Kuwait', 'Oman'],
};




const CreaNuovoSito = () => {
  const [cluster, setCluster] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [message, setMessage] = useState('');
  const [projectManagerName, setProjectManagerName] = useState('');
  const [projectManagerSurname, setProjectManagerSurname] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude1, setLatitude1] = useState('');
  const [longitude1, setLongitude1] = useState('');
  const [region, setRegion] = useState('');
  const [state, setState] = useState('');
  const [isBarraOpen, setIsBarraOpen] = useState(false);

  const [open, setOpen] = useState(false);
  
  const [severity, setSeverity] = useState('success'); // "success" o "error"

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    navigate('/Lista Siti');
  };
  const handleStateChange = (event) => {
    setState(event.target.value);
  }
  const navigate = useNavigate();
   
  const handleClusterChange = (event) => {
  
    const selectedRegion = event.target.value;
    
    setCluster(selectedRegion);
    
   //  setRegion(selectedRegion);
   // 
    const coords = regionCoordinates[selectedRegion];
    setLatitude(coords?.lat || '');
    setLongitude(coords?.lng || '');
    console.log('latitudine:', latitude, 'longitudine:', longitude);
  };


  useEffect(() => {
    const getCluster = async () => {
      if (selectedPlace) {
        try {
          const geocode = await geocodeByAddress(selectedPlace);
          const latLng = await getLatLng(geocode[0]);
          const latitude = latLng.lat;
          const longitude = latLng.lng;
          // Utilizza la posizione per determinare il cluster
          if (latitude > 43.5 && latitude < 44.5 && longitude > 10.5 && longitude < 11.5) {
            setCluster('NORD');
          } else if (latitude > 42.5 && latitude < 43.5 && longitude > 10.5 && longitude < 11.5) {
            setCluster('CENTRO');
          } else if (latitude > 41.5 && latitude < 42.5 && longitude > 10.5 && longitude < 11.5) {
            setCluster('SUD');
          }
        } catch (error) {
          console.error('Geocoding error: ', error);
        }
      }
    };
    getCluster();
  }, [selectedPlace]);

  const createSite = async (event) => {
    event.preventDefault();
    try {
      const url = '/nuovoSito';
      const data = { region, state,cluster, city, address, postalCode,latitude1,longitude1, projectManagerName, projectManagerSurname };
      console.log("dati:",data);
      const response = await axios.post(url, data);
      if (response.status === 200) {
        setMessage('Nuovo sito generato con successo');
        //navigate('/ListaSiti'); // Reindirizza alla pagina della mappa
        setOpen(true);

      } else if (response.status === 401) {
        setMessage('Sito gia esistente');
      } else {
        setMessage('Errore sconosciuto');
      }
    } catch (error) {
      setMessage('');
      console.error(error);
    }
  };


  const handleRegionChange = (event) => {
    const selectedRegion = event.target.value;
    setRegion(selectedRegion);
    const coords = regionCoordinates[selectedRegion];
    setLatitude(coords?.lat || '');
    setLongitude(coords?.lng || '');
    setState(''); // Resetta lo stato quando cambia la regione
  };

  const isAllFieldsFilled = () => {
    return (
      cluster &&
      city &&
      address &&
      postalCode &&
      projectManagerName &&
      projectManagerSurname
    );
  };


  const extractCity = (addressComponents) => {
    const cityComponent = addressComponents.find(component =>
      component.types.includes('administrative_area_level_3') || component.types.includes('political')
    );

    console.log(cityComponent);
    return cityComponent ? cityComponent.long_name : '';
  };

  const extractState = (addressComponents) => {
    const country = addressComponents.find(component =>
      component.types.includes('country') || component.types.includes('administrative_area_level_2')
    );
    return country ? country.long_name : '';
  };

  const extractPostalCode = (addressComponents) => {
    const postaCodeComponent = addressComponents.find(component =>
      component.types.includes('postal_code')
    );
    return postaCodeComponent ? postaCodeComponent.long_name : '';
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'city':
        setCity(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'projectManagerName':
        setProjectManagerName(value);
        break;
      case 'projectManagerSurname':
        setProjectManagerSurname(value);
        break;
      default:
        break;
    }
  };

  const handleSelect = async (selectedAddress) => {
  

    try {
      // Geocodifica l'indirizzo selezionato
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]); // Ottieni latitudine e longitudine
      setLatitude1(latLng.lat);
      setLongitude1(latLng.lng);
      // Estrarre la città e la nazione
      const addressComponents = results[0].address_components;
      
      if(!state)
      {
      setState(extractState(results[0].address_components));
      }
      setCity(extractCity(results[0].address_components));
      
      setPostalCode(extractPostalCode(results[0].address_components));
      
      
    } catch (error) {
      console.error('Errore nella geocodifica:', error);
    }
  };



  return (

    <div className='Container' >    
    <Barra isBarraOpen={isBarraOpen} toggleBarra={() => setIsBarraOpen(!isBarraOpen)} />
      <AppBar position="static">
        <Toolbar variant="dense" sx={{ backgroundColor: '#A0C646' }}>
          <Typography variant="h6" noWrap component="div" sx={{ mr: 2 }}>
            Crea Nuovo sito
          </Typography>
          
          <Button color="primary" variant="contained" sx={{ ml: 'auto' }} onClick={() => setIsBarraOpen(true)}>
            <AddCircleOutline />
          </Button>
        </Toolbar>
      </AppBar>
      <div  className ="CreaNuovoSito" style={{
        backgroundColor: '#F9F1FC', 
        padding: '40px',
        borderRadius: '5px',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        width: '100%',
        margin: '0 auto',  // Centrato orizzontalmente
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        height: '100vh',  // Imposta l'altezza del viewport per centrarlo verticalmente
        justifyContent: 'center',  // Centrato verticalmente
       }}
        >
  
    <Grid container spacing={2} justifyContent="center" alignItems="center" width="100%">   
      <Grid item xs={12} >
        <Select
          label="Seleziona Regione"
          value={region}
          onChange={handleRegionChange}
          fullWidth
          width="100%"
        >
          <MenuItem value="" sx={{fontSize:'16px'}}>Seleziona una Regione</MenuItem>
          {Object.keys(regionCoordinates).map((regionKey) => (
            <MenuItem key={regionKey} value={regionKey} sx={{fontSize:'16px'}}>
              {regionKey}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
          <Divider />
        </Grid>
      {region && regionStates[region] && (
        <Grid item xs={12}>
          <Select
            label="Seleziona Stato"
            value={state}
            onChange={handleStateChange}
            fullWidth
            width="100%"
          >
            <MenuItem value="" sx={{fontSize:'16px'}}>Seleziona uno Stato</MenuItem>
            {regionStates[region].map((state, index) => (
              <MenuItem key={index} value={state} sx={{fontSize:'16px'}}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}

      {state==='Italia' && (
        
      
      <Grid item xs={12}>
        <Select
          label="Seleziona un Cluster"
          value={cluster}
          onChange={handleClusterChange}
          fullWidth
          sx={{ fontSize: '16px' }}
        >
          <MenuItem value="">Seleziona un cluster</MenuItem>
          <MenuItem value="NORD">NORD</MenuItem>
          <MenuItem value="NORD-EST">NORD-EST</MenuItem>
          <MenuItem value="NORD-OVEST">NORD-OVEST</MenuItem>
          <MenuItem value="CENTRO">CENTRO</MenuItem>
          <MenuItem value="TIRRENICA">TIRRENICA</MenuItem>
          <MenuItem value="SUD">SUD</MenuItem>
          <MenuItem value="SUD-EST">SUD-EST</MenuItem>
          <MenuItem value="SUD-OVEST">SUD-OVEST</MenuItem>
         
         
        </Select>
      </Grid>
      )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
      <Grid item xs={12}>
        <TextField
          label="Città"
          name="city"
          value={city}
          onChange={handleInputChange}
          fullWidth
          sx={{ fontSize: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={(address) => {
            setAddress(address);
          
            setSelectedPlace(address); // Store the selected place
            handleSelect(address);
          }}
          searchOptions={{
            location: new window.google.maps.LatLng(41.8933203,12.4829321),
            radius: 20000,
            types: ['address'],
            key: API_KEY,
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField
                {...getInputProps({
                  placeholder: 'Cerca indirizzo...',
                  className: 'location-search-input',
                })}
                label="Indirizzo"
                name="address"
                fullWidth
                sx={{ fontSize: '16px' }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Caricamento...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = {
                    backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                    color: suggestion.active ? '#000' : '#444',
                    cursor: 'pointer',
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="CAP"
          name="postalCode"
          value={postalCode}
          onChange={handleInputChange}
          fullWidth
          sx={{ fontSize: '16px' }}

        />
      </Grid>
      <Grid item xs={12}>
          <Divider />
        </Grid>

      <Grid item xs={12}>
        <TextField
          label="Nome del project manager"
          name="projectManagerName"
          value={projectManagerName}
          onChange={handleInputChange}
          fullWidth
          sx={{ fontSize: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Cognome del project manager"
          name="projectManagerSurname"
          value={projectManagerSurname}
          onChange={handleInputChange}
          fullWidth
          sx={{ fontSize: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isAllFieldsFilled()}
          onClick={createSite}
        >
          Crea sito
        </Button>
      </Grid>
    </Grid>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
    </div>
  
  );
};

export default CreaNuovoSito;