import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'
const theme = createTheme({
    palette: {
      primary: {
        main: '#39414C', // Custom primary color
      },
      secondary: {
        main: '#FF4081', // Custom secondary color
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif', // Custom font family
    },
  });

  {/* Your application components go here */}

const NavigationBar = () => {
    const navigate = useNavigate();
  
    const handleLogin = ()=>
    {
         navigate("/Login");
    }


    const handleHome = ()=>
    {
        
         navigate("/Home");
    }
 
    const handleRegister = ()=>
    {
         navigate("/Register");
    }

    const handleContact = ()=>
    {
         navigate("/Contatti");
    }



  const handleChiSiamo = ()=>
    {
         navigate("/Chi Siamo");
    }

  return (
    <ThemeProvider theme={theme}>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        </Typography>  
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="C&Alogo.jpeg" alt="Logo" style={{ height: 40, marginRight: 750 }} />
        <Button color="inherit" onClick={handleHome}>Home </Button>
        <Button color="inherit" onClick={handleChiSiamo}>About</Button>
        <Button color="inherit"  onClick={handleContact}>Contact</Button>
        <Button 
        color= "inherit" 
        onClick={handleLogin}
        startIcon={<img src="logoSGS.png" alt="Login Icon" style={{ width: '24px', height: '24px' }} />} // Sostituisci con il tuo percorso immagine
        >Login</Button>
        <Button color ="inherit" onClick={handleRegister}>Sign-in </Button>
        <img src="Logo_LGS.jpg.png" alt="Logo" style={{ height: 30, marginLeft: 10}} />
        
        </Box>
      </Toolbar>
    </AppBar>
    </ThemeProvider>
  );
};

export default NavigationBar;